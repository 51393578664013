export const resourceEnum = {
  TEACHING_RESOURCE_TYPE: 1,
  EXERCISES_RESOURCE_TYPE: 2,
  PAPER_RESOURCE_TYPE: 3,
  KNOWLEDGE_POINTS: 4
};

export default {
  1: '教学库',
  2: '习题库',
  3: '试卷库',
  4: '知识点'
};