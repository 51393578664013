<template>
  <NCard size="small" :bordered="false" :title="labelListTitle" :segmented="segmented" class="label-list-card">
    <NSpin :show="loadingLabel">
      <NScrollbar :style="{ maxHeight: labelContentHeight }">
        <div class="label-wrap">
          <NSpace>
            <NTag
              v-if="showNoLabel"
              :key="0"
              checkable
              class="label-item"
              v-model:checked="selectNoLabel"
              @click="handleLabelSelect(0)"
            >未设置标签（{{ noLabelFileNum }}）</NTag>
            <NTag
              v-for="label in labelList"
              :key="label.id"
              checkable
              class="label-item"
              v-model:checked="label.selected"
              @click="handleLabelSelect()"
            >{{ label.title }}（{{ label.file_num }}）</NTag>
          </NSpace>
        </div>
      </NScrollbar>
    </NSpin>
  </NCard>
</template>

<script setup>
  import { ref, onMounted, computed, defineEmits, defineExpose, defineProps } from 'vue';

  import { getLabelList } from '@/api/label.js';
  import { resStatusEnum } from '@/enumerators/http.js';
  const { SUCCESS } = resStatusEnum;

  const props = defineProps({
    labelType: {
      type: Number,
      required: true
    },
    labelListTitle: {
      type: String,
      default: ''
    },
    preSelectedIdsArr: {
      type: Array,
      default: () => []
    },
    showNoLabel: {
      type: Boolean,
      default: false
    },

    labelContentHeight: {
      type: String,
      default: 'auto'
    }
  });

  const segmented = computed(() => {
    if (props.labelListTitle) {
      return { content: 'hard' };
    } else {
      return {};
    }
  });

  const loadingLabel = ref(false);
  const labelList = ref([]);
  const selectedLabelIdsArr = computed(() => {
    if (Array.isArray(labelList.value)) {
      return labelList.value.filter(elem => elem.selected).map(elem => elem.id);
    } else {
      return [];
    }
  });

  const noLabelFileNum = ref(0);
  const selectNoLabel = ref(false);

  const updateLabelList = () => {
    loadingLabel.value = true;
    getLabelList({
      type: props.labelType,
      no_page: 1
    }).then(res => {
      loadingLabel.value = false;
      if (res.code === SUCCESS) {
        labelList.value = Array.isArray(res.data.list) ?
          res.data.list.map(item => {
            item.selected = props.preSelectedIdsArr.includes(item.id);
            return item;
          }) :
          [];

        noLabelFileNum.value = Number(res.data.no_set_num) || 0;
      }
    }).catch(err => {
      loadingLabel.value = false;
    });
  }
  const getSelectedIds = () => {
    return selectNoLabel.value ?
      ['0'] :
      selectedLabelIdsArr.value;
  };
  const emit = defineEmits(['list-update']);
  const handleLabelSelect = labelId => {
    if (labelId === 0) { // “未设置标签”
      labelList.value.forEach(item => { 
        item.selected = false;
      });
      emit('list-update', {
        selectedIdsArr: ['0'],
        selectedLabelsArr: [{
          file_num: noLabelFileNum.value,
          id: '0',
          title: '未设置标签',
          type: props.labelType
        }]
      });
    } else {
      selectNoLabel.value = false;
      emit('list-update', {
        selectedIdsArr: [...selectedLabelIdsArr.value],
        selectedLabelsArr: labelList.value.filter(elem => elem.selected)
      });
    }
  }
  onMounted(() => {
    updateLabelList();
  });

  defineExpose({
    getSelectedIds,
    updateLabelList
  });

  /*
  <LabelList
    ref="labelListRef"
    label-list-title="标签列表"
    :label-type="resourceEnum.TEACHING_RESOURCE_TYPE"
    :pre-selected-ids-arr="[1, 2]"
    @list-update="xxx(selectedIdsArr, selectedLabelsArr)"
  />
  const labelListRef = ref(null);
  labelListRef.value.getSelectedIds()
  */
</script>

<style lang="less" scoped>
  @import "~@/styles/variables.less";

  .label-list-card {
    :deep(.n-card__content) {
      padding-right: 0;
    }
  }

  .label-wrap {
    ::v-deep(.label-item) {
      min-height: 28px;
      height: auto;
      border: 1px solid #e5e5e5;
      color: #999;
      border-radius: 3px;
      white-space: normal;
      word-break: break-all;
    }

    ::v-deep(.label-item:hover) {
      border-color: @theme-color;
    }

    ::v-deep(.label-item.n-tag--checked) {
      color: #fff;
    }
  }
</style>